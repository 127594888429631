import React, { useRef, memo } from 'react';
import ReactToPrint from 'react-to-print';
import {
    Button,
    ButtonDropdown,
    CardFooter,
    DropdownMenu,
    DropdownToggle,
    Input,
    Row,
} from 'reactstrap';
import { Colxx } from '../../../components/common/CustomBootstrap';
import ComponentToPrintNow from '../../../containers/pages/ComponentToPrintNow';
import ComponentToPrintForKitchen from '../../../containers/pages/ComponentToPrintForKitchen';

const FooterOrders = ({
    isOpen,
    toggle,
    customer_name,
    customer_phone,
    onChange,
    handleSubmit,
    handleSubmitChange,
    btnDropup,
    itemsPending,
    companyName,
    companyAddress,
    companyLogo,
    companyWebsite,
    billDate,
    pin_print,
    discount,
    tax_fee,
    fee_other,
    info_table,
    switchCheckedBill,
    switchCheckedBillBar,
    refund_to_customer,
    isChecked,
    itemsChange,
    removeOrders,
}) => {
    // console.log('itemsPending: ', itemsPending);
    let componentRef = useRef(null);
    let componentRefKitchen = useRef(null);
    return (
        <>
            <CardFooter>
                <Row className='mt-auto mt-2 mb-4'>
                    <Colxx xxs='6'>
                        <Input
                            //autoFocus
                            placeholder='Tên khách hàng'
                            onChange={onChange}
                            value={customer_name}
                            name='customer_name'
                            className='border-top-0 border-right-0 border-left-0 rounded-0 p-1'
                        />
                    </Colxx>
                    <Colxx xxs='6'>
                        <Input
                            placeholder='Số điện thoại'
                            onChange={onChange}
                            value={customer_phone}
                            name='customer_phone'
                            className='border-primary border-top-0 border-right-0 border-left-0 rounded-0 p-1'
                        />
                    </Colxx>
                </Row>
                {itemsChange?.length ? (
                    <Row className='d-flex justify-content-between align-items-center position-sticky-bottom px-2'>
                        <div
                            className='w-75'
                            onClick={() => handleSubmitChange()}
                        >
                            <Button className='w-100' color='primary'>
                                Hoàn tất điều chỉnh
                            </Button>
                        </div>
                        <div
                            className='w-25 pl-2'
                            onClick={() => removeOrders()}
                        >
                            <Button className='w-100' color='danger'>
                                Đóng
                            </Button>
                        </div>
                    </Row>
                ) : (
                    <Row className='d-flex justify-content-between align-items-center position-sticky-bottom px-2'>
                        <div
                            className='w-75'
                            onClick={() => handleSubmit('PAYMENTED')}
                        >
                            {switchCheckedBill ? (
                                refund_to_customer > 0 || isChecked ? (
                                    <Button className='w-100' color='primary'>
                                        {info_table !== ''
                                            ? 'Đặt hàng'
                                            : itemsChange?.length
                                            ? 'Hoàn tất điều chỉnh'
                                            : 'Hoàn tất thanh toán!'}
                                    </Button>
                                ) : (
                                    componentRef && (
                                        <ReactToPrint
                                            onClick={() =>
                                                handleSubmit('PAYMENTED')
                                            }
                                            trigger={() => (
                                                <Button
                                                    className='w-100'
                                                    color='primary'
                                                >
                                                    {info_table !== ''
                                                        ? 'Đặt hàng'
                                                        : itemsChange?.length
                                                        ? 'Hoàn tất điều chỉnh'
                                                        : 'Hoàn tất thanh toán!'}
                                                </Button>
                                            )}
                                            content={() => componentRef}
                                            onAfterPrint={() => {
                                                if (switchCheckedBillBar) {
                                                    // In hoá đơn bếp từ componentRefKitchen
                                                    return (
                                                        componentRefKitchen && (
                                                            <ReactToPrint
                                                                content={() =>
                                                                    componentRefKitchen
                                                                }
                                                            />
                                                        )
                                                    );
                                                }
                                            }}
                                        />
                                    )
                                )
                            ) : !switchCheckedBill && switchCheckedBillBar ? (
                                refund_to_customer > 0 || isChecked ? (
                                    <Button className='w-100' color='primary'>
                                        {info_table !== ''
                                            ? 'Đặt hàng'
                                            : itemsChange?.length
                                            ? 'Hoàn tất điều chỉnh'
                                            : 'Hoàn tất thanh toán!'}
                                    </Button>
                                ) : (
                                    componentRefKitchen && (
                                        <ReactToPrint
                                            onClick={() =>
                                                handleSubmit('PAYMENTED')
                                            }
                                            trigger={() => (
                                                <Button
                                                    className='w-100'
                                                    color='primary'
                                                >
                                                    {info_table !== ''
                                                        ? 'Đặt hàng'
                                                        : itemsChange?.length
                                                        ? 'Hoàn tất điều chỉnh'
                                                        : 'Hoàn tất thanh toán!'}
                                                </Button>
                                            )}
                                            content={() => componentRefKitchen}
                                        />
                                    )
                                )
                            ) : (
                                <Button className='w-100' color='primary'>
                                    {info_table !== ''
                                        ? 'Đặt hàng'
                                        : itemsChange?.length
                                        ? 'Hoàn tất điều chỉnh'
                                        : 'Hoàn tất thanh toán!'}
                                </Button>
                            )}
                        </div>
                        <ButtonDropdown
                            className='w-25 pl-2'
                            direction='up'
                            isOpen={isOpen}
                            toggle={toggle}
                        >
                            <DropdownToggle
                                className='w-100'
                                caret
                                color='primary'
                                direction='up'
                            >
                                Khác
                            </DropdownToggle>
                            <DropdownMenu right className='p-3 text-center'>
                                <span>
                                    {componentRef && (
                                        <ReactToPrint
                                            trigger={() => (
                                                <Button
                                                    color='primary'
                                                    className='mb-2 mx-auto'
                                                >
                                                    In hóa đơn tạm tính
                                                </Button>
                                            )}
                                            content={() => componentRef}
                                        ></ReactToPrint>
                                    )}
                                </span>
                            </DropdownMenu>
                        </ButtonDropdown>
                        <div style={{ display: 'none' }}>
                            <ComponentToPrintNow
                                items={itemsPending}
                                companyName={companyName}
                                companyAddress={companyAddress}
                                companyLogo={companyLogo}
                                companyWebsite={companyWebsite}
                                billDate={billDate}
                                pin_print={pin_print}
                                discount={discount}
                                tax_fee={tax_fee}
                                fee_other={fee_other}
                                ref={(el) => (componentRef = el)}
                                style={{ display: 'none' }}
                            />
                        </div>
                        <div style={{ display: 'none' }}>
                            <ComponentToPrintForKitchen
                                items={itemsPending}
                                billDate={billDate}
                                pin_print={pin_print}
                                discount={discount}
                                ref={(el) => (componentRefKitchen = el)}
                                style={{ display: 'none' }}
                            />
                        </div>
                    </Row>
                )}
            </CardFooter>
        </>
    );
};

export default memo(FooterOrders);
